body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.fa-size {
  font-size: 2rem;
}
.banner-kpi {
  font-size: 2rem;
}
@media (max-width: 991.98px) { 
  .nav-item {
      border-bottom: 1px #005557 solid;
  }
  .nav-item .nav-link {
      display: flex;
      flex-direction: row;
      padding-left: 1rem;
      align-items: center;
  }
  .nav-item .nav-link svg {
      margin-right: 1rem;
  }
  .fa-size {
      font-size: 1rem;
  }
  .banner-kpi {
      font-size: 1rem;
  }
}
