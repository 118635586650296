/* rwt standard */
/* .rwt-dark-footer-bg {
    background: #0A4158;
} */

.rwt-txt-dark-blue {
    color: #0A4158;
}

.rwt-txt-primary {
    color: #00A1C9 !important;
}

.rwt-txt-secondary{
    color: #EC7211 !important;
}

.rwt-bg-primary {
    background-color: #00A1C9;
}

.rwt-primary-btn {
    background-color: #00A1C9; 
    color: white; 
    transition: .5s;
    font-weight: bold;
} 
 
.rwt-primary-btn:hover {
    text-decoration: none;
    background-color: #0a85a4d5; 
    color: white; 
}

.rwt-txt-secondary a{
    color: #EC7211 !important;
}
.rwt-txt-secondary a:hover{
    color: #0a85a4d5 !important;
    transition: .4s;
}

.rwt-bg-secondary {
    background-color: #EC7211;
}

.rwt-border {
    border: 1px #00A1C9 solid;
}

/* footer */
.footer-txt {  
    color: #0A4158; 
}

.footer-txt:hover{
    color: #ec7211;
    transition: .3s;
}

/* override card */
.card {
    border: 1px #efefef solid;
}

.card:hover {
    box-shadow: 4px 4px 3px #e6e6e6;
}

/* carousel */
.react-multi-carousel-item {
    margin: 0 0.5rem;
}

/* nav */
.nav-item a:hover {
    color: #ec7211 !important;   
}

/* font size */
.fs-8rem {
    font-size: 0.5rem;
}

.fs-9rem {
    font-size: 0.5625rem;
}

.fs-10rem {
    font-size: 0.625rem;
}

.fs-11rem {
    font-size: 0.688rem;
}

.fs-12rem {
    font-size: 0.75rem;
}

.fs-13rem {
    font-size: 0.8125rem;
}

.fs-14rem {
    font-size: 0.875rem;
}

.fs-16rem {
    font-size: 1rem;
}

.fs-22rem {
    font-size: 1.375rem;
}

.fs-24rem {
    font-size: 1.5rem;
}

.fs-26rem {
    font-size: 1.625rem;
}

.fs-28rem {
    font-size: 1.75rem;
}

.fs-30rem {
    font-size: 1.875rem;
}
/* font size */

@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {}

/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {}

/* XX-Large devices (larger desktops) */
/* No media query since the xxl breakpoint has no upper bound on its width */